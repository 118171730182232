import React, { useContext } from "react";
import { Context } from "../../helpers/Context";
import { useWinners } from "../../helpers/Hooks/Api";
import { Oval } from "react-loader-spinner";

export default function GamifiedWinners() {
  const { state } = useContext(Context);
  const { gamifiedWinners } = state;
  const { winnerLoading } = useWinners();

  const handleGamifiedWinners = () => {
    if (gamifiedWinners?.length > 0) {
      return gamifiedWinners.map((winner, index) => (
        <div
          key={index}
          className="flex justify-between px-[10px] py-[10px] items-center bg-white md:gap-[100px]"
        >
          <div className="flex gap-[10px] items-center">
            <div className="w-[50px] h-[50px] overflow-hidden rounded-[100px]">
              <img
                src={winner?.image}
                alt=""
                className="object-cover w-full h-full"
              />
            </div>
            <div>
              <p className="text-[0.875rem] font-[700]">
                {winner?.username
                  ? "@" +
                    winner.username.charAt(0).toLowerCase() +
                    winner.username.slice(1)
                  : ""}
              </p>
              <p className="text-[0.75rem]">{winner?.rank}</p>
            </div>
          </div>
          <p className="text-[0.875rem] font-[700]">
            ₦{Number(winner?.interest_accumulated).toLocaleString()}
          </p>
        </div>
      ));
    } else {
      return <p className="text-center">No winners yet</p>;
    }
  };
  return (
    <div className="border-[0.5px] border-[#AA3499] rounded-[10px] mt-[30px] md:mt-0">
      <div className="flex bg-[#AA3499] px-[10px] py-[5px] gap-[10px] w-full rounded-t-[10px]">
        <img
          src="/assets/images/gameBadge.png"
          alt="top saver"
          className="w-[45px] h-[45px]"
        />
        <div>
          <p className="text-white text-[1rem] font-[700]">
            Gamified Savings Pool Winners
          </p>
          <div className="px-[3px] py-[2px] bg-[#49016A] text-white inline-block text-[0.625rem] font-[700]">
            {gamifiedWinners ? gamifiedWinners[0]?.month.toUpperCase() : ""}
          </div>
        </div>
      </div>
      <div className="w-full h-[250px] overflow-y-auto">
        {winnerLoading ? (
          <div className="flex justify-center">
            <Oval
              height={50}
              width={50}
              color="#290349"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#fff"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        ) : (
          handleGamifiedWinners()
        )}
      </div>
    </div>
  );
}
