import { useContext, useState } from "react";
import { Button, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Context } from "../../helpers/Context";
import { useRedeem } from "../../helpers/Hooks/Api";

const MyRewards = () => {
  const { state } = useContext(Context);
  const { referral_details } = state;

  const [openClaim, setOpenClaim] = useState(false);

  const { redeemTokens, redeemError, setRedeemError } = useRedeem();

  return (
    <>
      <Box
        sx={{
          padding: "20px 0",
          display: "flex",
          borderRadius: "5px",
          backgroundColor: "#E3C4FD",
          color: "#290349",
        }}
      >
        <Box
          sx={{
            border: "none",
            borderRight: "0.5px solid #FF1CF7",
            flex: "3",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img alt="" src="/assets/images/earnings.png" />
        </Box>

        <Box sx={{ paddingLeft: "40px", flex: "6" }}>
          <Typography
            variant="body2"
            fontWeight={200}
            marginBottom={0.8}
            paddingRight={0.5}
          >
            Increase earnings by 50%
          </Typography>
          <Typography variant="caption" lineHeight={1} paddingRight={0.5}>
            Increase referral earnings on each successful invite
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: "10px", my: "20px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: "1",
            gap: "4px",
          }}
        >
          <Box
            display={"flex"}
            sx={{
              py: "10px",
              display: "flex",
              color: "#592E6D",
              flexDirection: "column",
              alignItems: "center",
              border: "0.5px solid #FF1CF7",
              borderRadius: "5px",
              gap: 0,
            }}
            flex={"2"}
          >
            <Typography variant={"body2"}>Earned Rewards</Typography>

            <Typography fontSize={"28px"} lineHeight={"20px"}>
              {referral_details?.available_point}
              <span style={{ fontSize: "12px" }}>OC Tokens</span>
            </Typography>
            <Typography fontSize={"12px"}>
              &#8358;{referral_details?.available_point * 10}
            </Typography>
            <Button
              sx={{ textTransform: "none", color: "#592E6D" }}
              onClick={() => setOpenClaim(true)}
              // disabled
            >
              <Typography
                variant="caption"
                sx={{
                  fontWeight: "600",
                  textDecoration: "underline",
                }}
              >
                Redeem
              </Typography>
            </Button>
          </Box>
          <Box
            display={"flex"}
            sx={{
              py: "10px",
              display: "flex",
              color: "#592E6D",
              flexDirection: "column",
              alignItems: "center",
              border: "0.5px solid #FF1CF7",
              borderRadius: "5px",
              gap: 0,
            }}
            flex={"1"}
          >
            <Typography variant={"body2"}>Claimed Rewards</Typography>

            <Typography fontSize={"28px"} lineHeight={"20px"}>
              {referral_details?.total_cash_withdrawn}
              <span style={{ fontSize: "12px" }}>OC Tokens</span>
            </Typography>
            <Typography fontSize={"12px"}>
              &#8358;{referral_details?.total_cash_withdrawn * 10}
            </Typography>
          </Box>
        </Box>

        <Box
          display={"flex"}
          sx={{
            py: "10px",
            display: "flex",
            color: "#592E6D",
            flexDirection: "column",
            alignItems: "center",
            border: "0.5px solid #FF1CF7",
            borderRadius: "5px",
            flex: "1",
          }}
        >
          <Typography variant={"body2"}>Expected Rewards</Typography>
          <img src="/assets/images/cash.svg" alt="Cash" />
          <Typography fontSize={"28px"} color={"rgba(89, 46, 109, 0.5)"}>
            {referral_details?.total_redeemeable_point -
              referral_details?.available_point}
            <span style={{ fontSize: "12px" }}>OC Tokens</span>
          </Typography>
          <Typography fontSize={"12px"}>
            &#8358;
            {(referral_details?.total_redeemeable_point -
              referral_details?.available_point) *
              10}
          </Typography>
          <Typography fontSize={"10px"} textAlign={"center"}>
            You’ll earn this once your friends join the savings pool
          </Typography>
        </Box>
      </Box>

      <section className="mt-[40px]">
        <div className=" text-[#592E6D] text-[0.625rem] font-AR font-[700] underline">
          Note:
        </div>
        <div className=" flex flex-col gap-y-[8px] mt-[4px]">
          <div className=" flex items-center gap-x-[23px]">
            <span className=" text-[#592E6D] text-[0.75rem] font-AR">
              10 OC Tokens is Equivalent to{" "}
            </span>
            <span className=" text-[#592E6D] text-[0.75rem] font-AR font-[500]">
              &#8358;100
            </span>
          </div>
          <div className=" flex items-center gap-x-[23px]">
            <span className=" text-[#592E6D] text-[0.75rem] font-AR">
              100 OC Tokens is Equivalent to{" "}
            </span>
            <span className=" text-[#592E6D] text-[0.75rem] font-AR font-[500]">
              &#8358;1000
            </span>
          </div>

          <div className=" text-[#592E6D] text-[0.75rem] font-AR font-[700]">
            Minimum Token Withdrawal is 100 OC Tokens
          </div>
        </div>
      </section>

      <Modal
        open={openClaim}
        onClose={() => {
          setRedeemError("");
          setOpenClaim(false);
        }}
        className={`flex items-center`}
      >
        <>
          <section
            className={`w-[24.2574rem] h-[230px] m-[0_auto] p-[10px] transition-transform duration-700 ease-in-out bg-[#fff] shadow-[0px_11px_15px_0px_rgba(0,0,0,0.20),0px_9px_46px_0px_rgba(0,0,0,0.12),0px_24px_38px_0px_rgba(0, 0, 0, 0.14)] rounded-[4px] flex flex-col items-center`}
          >
            <div
              className="flex self-start justify-end w-full cursor-pointer "
              onClick={() => {
                setRedeemError("");
                setOpenClaim(false);
              }}
            >
              <img
                src="/assets/images/closeBold.png"
                alt=""
                className=" h-[24px] w-[24px]"
              />
            </div>
            <div className="text-[0.875rem] text-[#592E6D] font-SF font-[600] flex  flex-col items-center justify-center mt-[20px]">
              <span className="pt-[5px]">
                You have {referral_details?.available_point} OC tokens which is
                equivalent to
              </span>

              <span className=" font-[700]">
                N {referral_details?.available_point * 10}
              </span>
            </div>
            <small
              className={`${
                redeemError ===
                "successfully transferred referral points to main wallet"
                  ? "text-[#25CF43]"
                  : "text-[#f00]"
              } text-[0.625rem] font-AR mt-[10px]`}
            >
              {redeemError}
            </small>
            <button
              className=" rounded-[5px] text-white w-[150px] h-[30px] bg-[#592E6D] mt-[30px] disabled:bg-[rgba(89,46,109,0.30)] disabled:text-[rgba(255,255,255,0.20)]"
              onClick={redeemTokens}
            >
              Redeem
            </button>
          </section>
        </>
      </Modal>
    </>
  );
};

export default MyRewards;
