import { useContext } from "react";
import { Context } from "./Context";

export const MaskedEmail = () => {
  const { state } = useContext(Context);
  const { userProfile } = state;
  if(userProfile?.user?.email){
    const email = userProfile?.user?.email;
    const [localPart, domain] = email?.split("@");
    const maskedLocal = localPart?.substring(0, 6) + "***";
    return   `${maskedLocal}@${domain}` ;
  }else{
    return "...3"
  }
 
};
