import React from "react";
import { useNavigate } from "react-router-dom";

export default function QuickLinks() {
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex flex-col text-[0.875rem] text-brandPurp font-[400] font-SF my-[18px]">
        Quick Links
      </div>
      <div className="flex">
        <button
          className="flex flex-col w-full h-[90px] bg-[#702F90] items-center justify-center px-[4px] gap-[2px] border-2 border-white  md:w-[110px] md:h-[100px] md:px-[10px]"
          onClick={() => navigate("/jackpot-pool")}
        >
          <img
            src="/assets/images/jackpotIcon.png"
            alt=""
            className="w-[30px] h-[30px]"
          />
          <p className="text-[0.625rem] text-white leading-3">
            Ultimate Jackpot Pool
          </p>
        </button>
        <button
          className="flex flex-col w-full h-[90px] bg-[#702F90] items-center justify-center px-[4px] gap-[2px] border-2 border-white md:w-[110px] md:h-[100px] md:px-[10px]"
          onClick={() => navigate("/gamefi-pool")}
        >
          <img
            src="/assets/images/nairaIcon.png"
            alt=""
            className="w-[30px] h-[30px]"
          />
          <p className="text-[0.625rem] text-white leading-3">
            Naira Gamified Pool
          </p>
        </button>
        <button
          className="flex flex-col w-full h-[90px] bg-[#702F90] items-center justify-center px-[4px] gap-[2px] border-2 border-white md:w-[110px] md:h-[100px] md:px-[10px]"
          onClick={() => navigate("/community-pool/intro")}
        >
          <div className="flex justify-end w-full">
            <p className="font-[700] text-[0.5rem] bg-[#DD0000] text-white mr-[5px]">
              New
            </p>
          </div>
          <img
            src="/assets/images/communityIcon.png"
            alt=""
            className="w-[30px] h-[30px]"
          />
          <p className="text-[0.625rem] text-white leading-3">
            Community Jackpot Pool
          </p>
        </button>
        <button
          className="flex flex-col w-full h-[90px] bg-[#702F90] items-center justify-center px-[4px] gap-[2px] border-2 border-white md:w-[110px] md:h-[100px] md:px-[10px]"
          onClick={() => navigate("/quiz")}
        >
          <div className="flex justify-end w-full">
            <p className="font-[700] text-[0.5rem] bg-[#DD0000] text-white mr-[5px]">
              New
            </p>
          </div>
          <img
            src="/assets/images/quizBook.png"
            alt=""
            className="w-[30px] h-[30px] mx-auto my-0"
          />
          <p className="text-[0.625rem]  text-white leading-3">Learn-2-Earn</p>
        </button>
      </div>
      <div className="flex">
        <button
          className="flex flex-col w-full h-[90px] bg-[#702F90] items-center justify-center px-[4px] gap-[2px] border-2 border-white md:w-[110px] md:h-[100px] md:px-[10px]"
          onClick={() => navigate("/settings/feedback")}
        >
          <div className="flex justify-end w-full">
            <p className="font-[700] text-[0.5rem] bg-[#DD0000] text-white mr-[5px]">
              New
            </p>
          </div>
          <img
            src="/assets/images/feedBack.png"
            alt=""
            className="w-[30px] h-[30px]"
          />
          <p className="text-[0.625rem] text-white leading-3">Feedback</p>
        </button>
        <button
          className="flex flex-col w-full h-[90px] bg-[#702F90] items-center justify-center px-[4px] gap-[6px] border-2 border-white md:w-[110px] md:h-[100px] md:px-[10px]"
          onClick={() => navigate("/transaction-history")}
        >
          <img
            src="/assets/images/transHistoryWhite.png"
            alt=""
            className="w-[20px] h-[26px]"
          />
          <p className="text-[0.625rem]  text-white leading-3">
            Transaction History
          </p>
        </button>
        <button
          className="flex flex-col w-full h-[90px] bg-[#702F90] items-center justify-center px-[4px] gap-[10px] border-2 border-white md:w-[110px] md:h-[100px] md:px-[10px]"
          onClick={() => navigate("/referrals")}
        >
          <img
            src="/assets/images/referral.png"
            alt=""
            className="w-[25px] h-[25px]"
          />
          <p className="text-[0.625rem] text-white leading-3">Referrals</p>
        </button>
        <button
          className="flex flex-col w-full h-[90px] bg-[#702F90] items-center justify-center px-[4px] gap-[10px] border-2 border-white md:w-[110px] md:h-[100px] md:px-[10px]"
          onClick={() => navigate("/rewards")}
        >
          <img
            src="/assets/images/rewardWhite.png"
            alt=""
            className="w-[25px] h-[25px]"
          />
          <p className="text-[0.625rem] text-white leading-3">Rewards</p>
        </button>
      </div>
    </div>
  );
}
