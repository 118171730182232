import {
  Box,
  Card,
  Paper,
  Typography,
  Button,
  Modal,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { NumericFormat } from "react-number-format";

import axios from "../../helpers/axios";
import { useState, useEffect, useContext } from "react";

import { ArrowBackIos } from "@mui/icons-material/";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { Audio } from "react-loader-spinner";
import {
  useAccountDetails,
  useUserBanks,
  useUserWalletData,
} from "../../helpers/Hooks/Api";
import { Context } from "../../helpers/Context";
import { useCopyToClipboard } from "../../helpers/Hooks/Copy";
import { formstyles } from "../../helpers/Styles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "300px",
  bgcolor: "#fff",
  boxShadow: 24,
  padding: "20px 10px",
  textAlign: "center",
  borderRadius: "5px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};

function WalletPage() {
  useUserWalletData();
  const navigate = useNavigate();
  const { state } = useContext(Context);
  const { userWalletData } = state;
  const { isLoading } = useAccountDetails();

  const [pageNum, setPageNum] = useState(1);

  return (
    <Box maxWidth={"500px"} sx={{ margin: "0 auto" }}>
      <Box>
        <Box
          sx={{
            padding: "24px 20px",
            borderBottom: "0.1px solid #FFFFFF80",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              marginBottom: "10px",
              position: "relative",
            }}
          >
            <ArrowBackIos
              sx={{
                fontSize: "16px",
                cursor: "pointer",
                position: "absolute",
                left: "0",
              }}
              onClick={() => {
                navigate(-1);
              }}
            />
            <Typography className="text-[1rem] text-[rgba(255,255,255,0.87)] font-SF">
              Wallet
            </Typography>
          </Box>
        </Box>
        <Box sx={{ padding: "24px 20px" }}>
          <Card
            sx={{
              background: "rgba(255, 255, 255, 0.1)",
              border: "0.01px solid rgba(255,28,247,0.4)",
              padding: "20px",
              color: "#fff",
              borderRadius: "10px",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography className="text-[0.875rem] text-[rgba(255,255,255,0.87)] font-SF font-[200]">
                Wallet ID
              </Typography>
              <Typography className="text-[0.875rem] text-[rgba(255,255,255,0.87)] font-SF font-[200]">
                Available Funds
              </Typography>
            </Box>
            <Box
              mt={""}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {isLoading ? (
                <div className="animate-pulse w-[70px] h-[10px] bg-white rounded opacity-75 mt-[5px]"></div>
              ) : (
                <Typography className="text-[0.875rem] text-white font-SF font-[400]">
                  {userWalletData?.wallet_id}
                </Typography>
              )}

              {isLoading ? (
                <div className="animate-pulse w-[70px] h-[20px] bg-white rounded-lg opacity-75 mt-[10px]"></div>
              ) : (
                <Typography variant="h5" sx={{ fontWeight: "500" }}>
                  <span style={{ fontSize: "16px" }}>N</span>
                  <span className=" text-[1.5rem] font-[400]">
                    {parseFloat(userWalletData?.funds)
                      .toFixed(0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    .
                  </span>
                  <span className="text-[0.875rem] ">
                    {userWalletData?.funds?.toString().split(".")[1] ===
                    undefined
                      ? "0"
                      : userWalletData?.funds
                          ?.toString()
                          .split(".")[1]
                          ?.slice(0, 2)}
                  </span>
                </Typography>
              )}
            </Box>
            <Box mt={"30px"}>
              <Box sx={{ display: "flex", gap: "6px" }}>
                <Typography className="text-[0.875rem] text-[rgba(255,255,255,0.87)] font-SF font-[200]">
                  Status:
                </Typography>
                <Typography className="text-[0.875rem] text-white font-SF font-[400]">
                  Active
                </Typography>
              </Box>
            </Box>
          </Card>
        </Box>
      </Box>
      <Box sx={{ padding: "1px 20px", pb: "60px", background: "#fff" }}>
        <Paper
          sx={{
            marginTop: "-10px",
            border: "0.5px solid #FF1CF7",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            padding: "20px",
          }}
        >
          <div
            className="walleTabs"
            style={{
              display: "flex",
            }}
          >
            <div
              className="Tab"
              style={{
                flex: 1,
                padding: " 10px 30px",
                color: "#290349",
                borderBottom: pageNum === 1 ? "2px solid #FF1CF7" : "none",
                fontWeight: "700",
              }}
            >
              <Typography
                variant="body2"
                textAlign={"center"}
                sx={{ fontWeight: "600", cursor: "pointer" }}
                onClick={() => setPageNum(1)}
              >
                Fund Wallet
              </Typography>
            </div>
            <div
              className="Tab"
              style={{
                padding: "10px 30px",
                color: "#290349",
                flex: 1,
                borderBottom: pageNum === 2 ? "2px solid #FF1CF7" : "none",
              }}
            >
              <Typography
                variant="body2"
                textAlign={"center"}
                onClick={() => setPageNum(2)}
                sx={{ cursor: "pointer" }}
              >
                Withdraw
              </Typography>
            </div>
          </div>
          <div style={{ marginTop: "30px", color: "#592E6D" }}>
            {pageNum === 1 && <FundWallet />}
            {pageNum === 2 && <WithdrawFund />}
          </div>
        </Paper>
      </Box>
    </Box>
  );
}
function FundWallet() {
  useAccountDetails();
  const { handleCopyClick, isCopied } = useCopyToClipboard();
  const { state } = useContext(Context);
  const { accountDetails, noBankDetails: noDetails } = state;
  const { isLoading } = useAccountDetails();

  return (
    <div>
      {!noDetails && (
        <>
          <div className=" mt-[21px] text-[0.75rem] text-[#592E6D] font-AR flex flex-col gap-y-[10px]">
            <p className="tracking-[-0.078px] leading-[18px]">
              Send money to the bank details below to fund your account.
            </p>
            <p className="tracking-[-0.078px] leading-[18px]">
              Add funds to your OneCap wallet
            </p>
          </div>
          <div className="mt-[46px] flex flex-col gap-y-[15px]">
            <div className=" flex items-center justify-between border-[1px] border-[#592E6D] rounded-[5px] h-[56px] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] p-[8px_10px]">
              <div className=" text-[0.75rem] font-AR">
                <p>ACCOUNT NUMBER</p>
                {isLoading ? (
                  <div className="animate-pulse w-[70px] h-[7px] bg-brandPurp rounded opacity-75 mt-[5px]"></div>
                ) : (
                  <p className=" font-[700]">
                    {accountDetails?.account_number}
                  </p>
                )}
              </div>
              <img
                src="/assets/images/copyFund.png"
                alt="copy"
                className="cursor-pointer "
                onClick={() => handleCopyClick(accountDetails?.account_number)}
              ></img>
            </div>

            <div className=" flex items-center justify-between border-[1px] border-[#592E6D] rounded-[5px] h-[56px] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] p-[8px_10px]">
              <div className=" text-[0.75rem] font-AR">
                <p>BANK NAME</p>
                {isLoading ? (
                  <div className="animate-pulse w-[100px] h-[7px] bg-brandPurp rounded opacity-75 mt-[5px]"></div>
                ) : (
                  <p className=" font-[700]">
                    {accountDetails?.bank?.toUpperCase()}
                  </p>
                )}
              </div>
              <img
                src="/assets/images/copyFund.png"
                alt="copy"
                className="cursor-pointer "
                onClick={() => handleCopyClick(accountDetails?.bank)}
              ></img>
            </div>

            <div className=" flex items-center justify-between border-[1px] border-[#592E6D] rounded-[5px] h-[56px] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] p-[8px_10px]">
              <div className=" text-[0.75rem] font-AR">
                <p>ACCOUNT NAME</p>
                {isLoading ? (
                  <div className="animate-pulse w-[200px] h-[7px] bg-brandPurp rounded opacity-75 mt-[5px]"></div>
                ) : (
                  <p className=" font-[700]">
                    {accountDetails?.name?.toUpperCase()}
                  </p>
                )}
              </div>
              <img
                src="/assets/images/copyFund.png"
                alt="copy"
                className="cursor-pointer "
                onClick={() => handleCopyClick(accountDetails?.name)}
              ></img>
            </div>

            <div
              className={`text-[0.625rem] text-center transition-opacity duration-300 ease-in-out ${
                isCopied ? "opacity-100" : "opacity-0"
              }`}
            >
              Text Copied
            </div>
          </div>
          <div className="mt-[30px] text-center">
            <Link
              to={"/dashboard/wallet/deposit-withdrawal-history"}
              state={{ pageNum: 1 }}
              className="text-[#290349] text-[0.9rem] font-ENL text-center underline"
            >
              Deposit History
            </Link>
          </div>
        </>
      )}
      {noDetails && (
        <section
          className={` h-[230px] m-[0_auto] p-[10px] mt-[2rem] transition-transform duration-700 ease-in-out bg-[#fff] shadow-[0px_11px_15px_0px_rgba(0,0,0,0.20),0px_9px_46px_0px_rgba(0,0,0,0.12),0px_24px_38px_0px_rgba(0, 0, 0, 0.14)] rounded-[4px] flex flex-col items-center`}
        >
          <div className="text-[1rem] text-[#592E6D] text-center font-SF font-[600] flex items-center justify-center mt-[37px]">
            You have no account details yet!
          </div>
          <div className=" max-w-[20.375rem] text-center m-[0_auto] text-[0.75rem] text-[#592E6D] font-SF font-[400]"></div>

          <div className="flex items-center gap-x-[18px] mt-[23px]">
            <div className="text-center">
              {" "}
              <Link
                to={"/settings/bvn"}
                className="text-[#290349] font-ENL text-center underline"
              >
                Please verify your BVN
              </Link>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}
function WithdrawFund() {
  useUserBanks();
  const { state } = useContext(Context);
  const { userBanks: banks, userWalletData } = state;
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const [bankDetail, setBankDetails] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => {
    setOpenModal(false);
  };
  const [serverError, setServerError] = useState("");
  const [amount, setAmount] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");

  const [floatAmount, setFloatAmount] = useState("");
  const [errors, setErrors] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const { handleSubmit, register } = useForm({});
  const handleChange = (event) => {
    setBankDetails(event.target.value);
  };

  const onSubmit = async (data) => {
    data.amount = floatAmount;
    if (errors.length > 0) {
    } else {
      setServerError(
        <Audio
          height="20"
          width="20"
          radius="9"
          color="#592E6D"
          ariaLabel="three-dots-loading"
        />
      );
      await axios
        .post("/withdrawals/v2", data, config)
        .then(function (response) {
          setAmount(data.amount);
          setServerError(response?.data?.message);
          if (response.status === 200) {
            setShowPopUp(true);
          }
        })
        .catch(function (error) {
          console.log(error);

          if (error?.response?.status === 400) {
            const errorData = error?.response?.data;

            const firstError =
              typeof errorData === "object"
                ? Object.values(errorData)[0]
                : errorData;

            // Set the server error
            setServerError(firstError || "An error occurred.");
          }

          if (error?.response?.status === 403) {
            setServerError(error?.response?.data?.message);
          }
          if (error.response.data.amount) {
            setServerError(error.response.data.amount[0]);
          }
          if (error?.response?.data?.account_number) {
            setServerError(error?.response?.data?.account_number[0]);
          }
          if (error?.response?.data?.detail) {
            setServerError(error?.response?.data?.detail);
          }
        });
    }
  };

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
        <Typography variant="body2" sx={{ marginTop: "10px" }}>
          WITHDRAW FUNDS TO YOUR BANK ACCOUNT
        </Typography>
        <Typography variant="body2" sx={{ marginY: "10px" }}>
          Enter amount
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            color: "#fff",
          }}
        >
          <Box width={"full"}>
            <Box
              width={"100%"}
              sx={{
                borderRadius: "5px",
                border: "0.5px solid #592E6D",
                padding: "3px 0 3px 3px",
              }}
            >
              <NumericFormat
                value={withdrawAmount}
                placeholder="Amount"
                required
                allowLeadingZeros
                thousandSeparator=","
                style={{
                  width: "full",
                  outline: "2px solid transparent",
                  border: "0",
                  padding: "6px 6px",
                  borderRadius: "5px",
                  color: "#592E6D",
                  fontSize: "16px",
                }}
                onChange={(e) => {
                  let amount = e.target.value;
                  let floatAmount = parseFloat(amount.replaceAll(",", ""));
                  let inputErrors = [];

                  if (floatAmount < 1000) {
                    inputErrors.push(
                      "Minimum withdrawal must not be less than 1000"
                    );
                    setErrors(inputErrors);
                  } else {
                    setErrors([]);
                    setFloatAmount(floatAmount.toString());
                  }
                }}
              />
            </Box>

            <Box textAlign={"left"}>
              {errors.length > 0 && (
                <Typography my={"2"} color={"red"} fontSize={"xs"}>
                  {errors[0]}
                </Typography>
              )}
            </Box>
          </Box>

          <Typography
            variant="body2"
            sx={{ textAlign: "right", paddingRight: "5px" }}
            color="red"
          >
            {errors.amount?.message}
          </Typography>

          <Typography variant="body2" color={"rgb(89, 46, 109)"}>
            Choose Bank
          </Typography>
          <Box
            sx={{
              borderRadius: "5px",
              border: "0.5px solid #592E6D",
              padding: "3px 0 3px 3px",
            }}
          >
            <FormControl fullWidth>
              <Select
                required
                disableUnderline
                fullWidth
                variant="standard"
                name="user_bank"
                {...register("user_bank", {
                  required: "Account Number is required",
                })}
                id="demo-simple-select"
                value={bankDetail}
                placeholder="Choose Bank"
                label="Bank"
                error={errors.bank?.message}
                sx={{
                  color: "#592E6D",
                  marginTop: 0,
                  border: "none",
                  "& .MuiFormLabel-root": {
                    color: "#592E6D",
                  },
                  "& .MuiInputBase-root.MuiInput-root.MuiSelect-root": {
                    marginTop: "0px !important",
                  },
                  "& .css-21r7d85-MuiInputBase-root-MuiInput-root-MuiSelect-root":
                    { marginTop: "0px !important" },
                  "& .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input":
                    {
                      paddingLeft: "6px",
                    },
                }}
                onChange={handleChange}
              >
                {banks.length > 0 &&
                  banks.map((bank) => {
                    return (
                      <MenuItem key={bank.id} value={bank.id}>
                        {bank.bank} ({bank.account_number})
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <Typography
              variant="body2"
              sx={{ textAlign: "right", paddingRight: "5px" }}
              color="red"
            >
              {errors.bank?.message}
            </Typography>
          </Box>
        </Box>

        <p className="text-[#592E6D] text-[0.9rem] pt-[20px]">
          Enter your Transaction Pin
        </p>

        <Box
          sx={{
            borderRadius: "5px",
            border: "0.5px solid #592E6D",
            padding: "5px 0 5px 3px",
            marginBottom: "5px",
            marginTop: "10px",
          }}
        >
          <TextField
            required
            fullWidth
            type="password"
            name="pin"
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
            sx={formstyles}
            {...register("pin", {
              required: "Pin is required",
              minLength: {
                value: 4,
                message: "Pin must be atleast 4 characters",
              },
              maxLength: {
                value: 4,
                message: "Pin cannot be more than 4 characters",
              },
            })}
            error={!!errors.pin}
            helperText={errors.pin?.message}
          />
        </Box>

        <button
          onClick={() => {
            navigate("/settings/transaction-pin");
          }}
          className="text-[#592E6D] text-[0.9rem] underline mb-[10px] text-right w-full"
        >
          {userWalletData?.is_set_pin
            ? "Forgot Transaction Pin"
            : "No Transaction Pin Yet"}
        </button>

        <Typography variant="caption">{serverError}</Typography>
        <Box mt={"20px"} sx={{ display: "flex", flexDirection: "column" }}>
          {banks.length < 1 ? (
            <Typography
              textAlign={"center"}
              variant="caption"
              sx={{ textDecoration: "underline" }}
            >
              <Link to="/settings/banks">Add Bank Account to Withdraw</Link>
            </Typography>
          ) : (
            // <Button
            //   type="submit"
            //   sx={{
            //     background: "#592E6D",
            //     ":hover": {
            //       bgcolor: "#a852dd",
            //     },
            //     color: "#fff",
            //     height: "45px",
            //     textTransform: "none",
            //   }}
            // >
            //   <Typography variant="body2">Continue</Typography>
            // </Button>
            <p className="text-center">Please check back later</p>
          )}
        </Box>
      </form>
      {showPopUp && <SuccessPopUp />}
      {/* {openModal && <ModalScreen action={handleClose} amount={amount} />} */}
      <Box sx={{ textAlign: "center", mt: "10px" }}>
        <Typography variant="caption" sx={{ textDecoration: "underline" }}>
          <Link
            to={"/dashboard/wallet/deposit-withdrawal-history"}
            state={{ pageNum: 2 }}
            className="text-[#290349] font-ENL text-[0.9rem] text-center underline"
          >
            Withdrawal History
          </Link>
        </Typography>
      </Box>
    </Box>
  );
}

const SuccessPopUp = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full h-full fixed inset-0 bg-[rgba(0,0,0,0.5)] flex items-center justify-center z-50 text-[#592E6D]">
      <div className="relative flex flex-col items-center justify-center bg-white p-[30px] rounded-md shadow-lg">
        <div className="flex justify-end w-full">
          <img
            src="/assets/images/close.png"
            alt="close"
            onClick={() => navigate("/dashboard")}
          />
        </div>
        <p className="text-center ">Success </p>
        <p className="text-center text-[0.9rem] py-[15px]">
          Withdrawal completed successfully, please verify your bank balance.
        </p>
      </div>
    </div>
  );
};

const ModalScreen = (props) => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const ref = localStorage.getItem("wID");
  const [serverMsg, setServerMsg] = useState("");
  const [open, setOpen] = useState(true);
  const [modalPage, setModalPage] = useState(1);
  const [otp, setOtp] = useState(new Array(4).fill(""));

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };
  const onSubmitModal = async (data) => {
    data = {
      otp: otp.join(""),
    };
    setServerMsg(
      <Audio
        height="20"
        width="20"
        radius="9"
        color="#592E6D"
        ariaLabel="three-dots-loading"
      />
    );
    await axios
      .post(`/otp/withdrawal/${ref}`, data, config)
      .then(function (response) {
        setServerMsg(response?.data?.message);
        localStorage.removeItem("wID");
        setTimeout(setModalPage(2), "3000");
      })
      .catch(function (error) {
        console.log(error);
        setServerMsg(error?.response?.data?.message);
      });
  };
  const navigate = useNavigate();

  return (
    <div>
      <Modal
        open={open}
        onClose={props.action}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {modalPage === 1 && (
            <div className="flex flex-col items-center ">
              <div className="text-center w-[300px] text-[#592E6D]">
                Verify withdrawal of &#8358;
                {props.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.
                <br />
                An OTP has been sent to your email.
              </div>

              <Box mt="10px">
                <div className="otpInput">
                  {otp.map((data, index) => {
                    return (
                      <input
                        style={{
                          borderBottom: "1px solid #592E6D",
                          color: "#592E6D",
                        }}
                        className="otpField"
                        type="number"
                        name="otp"
                        maxLength={1}
                        key={index}
                        value={data}
                        pattern="[0-9]"
                        required
                        onChange={(e) => handleChange(e.target, index)}
                        onFocus={(e) => e.target.select()}
                      />
                    );
                  })}
                </div>
              </Box>

              <Box
                mt={"40px"}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  color: "#592E6D",
                  alignItems: "center",
                  padding: "0 10px",
                }}
              >
                <div className="flex gap-[100%] justify-center">
                  <Box
                    sx={{ cursor: "pointer" }}
                    color={"#592E6D"}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <ArrowBackIos sx={{ fontSize: "16px" }} />
                    <p onClick={props.action} className="text-[0.75rem]">
                      Back
                    </p>
                  </Box>

                  <button
                    className="bg-[#592E6D] hover:bg-[#a852dd] py-[5px] px-[20px] text-[#fff] text-[0.75rem] rounded"
                    onClick={() => {
                      onSubmitModal();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </Box>
              <Typography color={"red"} variant="caption">
                {serverMsg}
              </Typography>
            </div>
          )}

          {/* secondmodal */}

          {modalPage === 2 && (
            <>
              <Typography
                variant="body2"
                mt="40px"
                sx={{
                  width: "300px",
                  marginRight: "auto",
                  marginLeft: "auto",
                  fontWeight: "600",
                  color: "#592E6D",
                }}
              >
                Success!
              </Typography>
              <Typography
                mt={"12px"}
                sx={{ fontSize: "13px", color: "#592E6D" }}
              >
                Your withdrawal request has been received. Your bank account
                will be credited shortly.
              </Typography>

              <Box
                mt={"40px"}
                sx={{
                  color: "#592E6D",
                  padding: "0 10px",
                }}
              >
                <Button
                  onClick={() => {
                    setOpen(false);
                    navigate("/dashboard");
                    //redirect to home page
                  }}
                  sx={{
                    background: "#592E6D",
                    ":hover": {
                      bgcolor: "#a852dd",
                    },
                    padding: "2px 20px",
                    color: "#fff",
                    textTransform: "none",
                    fontSize: "12px",
                  }}
                >
                  Close
                </Button>
              </Box>
            </>
          )}
        </Box>

        {/* second modal */}
      </Modal>
    </div>
  );
};

export default WalletPage;
