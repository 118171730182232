import React, { useContext } from "react";
import { Context } from "../../helpers/Context";
import { useWinners } from "../../helpers/Hooks/Api";
import { Oval } from "react-loader-spinner";

export default function TopSaver() {
  const { state } = useContext(Context);
  const { topSaver } = state;
  const { winnerLoading } = useWinners();

  const handleTopSaver = () => {
    if (topSaver) {
      return (
        <div className="flex justify-between px-[10px] py-[10px] items-center bg-white md:gap-[100px]">
          <div className="flex gap-[10px] items-center">
            <div className="w-[50px] h-[50px] overflow-hidden rounded-[100px]">
              <img
                src={topSaver?.image}
                alt=""
                className="object-cover w-full h-full"
              />
            </div>
            <div>
              <p className="text-[0.875rem] font-[700]">
                {topSaver?.username
                  ? "@" +
                    topSaver.username.charAt(0).toLowerCase() +
                    topSaver.username.slice(1)
                  : ""}
              </p>
              <p className="text-[0.75rem]">{topSaver?.rank}</p>
            </div>
          </div>
          <img
            src="/assets/images/topBadge.png"
            alt=""
            className="w-[50px] h-[40px]"
          />
        </div>
      );
    } else {
      return <p className="text-center">No top saver yet</p>;
    }
  };
  return (
    <div className="border-[0.5px] border-[#9563AF] rounded-[10px] mt-[10px] md:mt-0">
      <div className="flex bg-[#9563AF] px-[10px] py-[5px] gap-[10px] w-full rounded-t-[10px]">
        <img
          src="/assets/images/topTrophy.png"
          alt="top saver"
          className="w-[45px] h-[45px]"
        />
        <div>
          <p className="text-white text-[1rem] font-[700]">
            Top Saver of the Month
          </p>
          <div className="px-[3px] py-[2px] bg-[#C122C3] text-white inline-block text-[0.625rem] font-[700]">
            {topSaver ? topSaver?.month.toUpperCase() : ""}
          </div>
        </div>
      </div>
      {winnerLoading ? (
        <div className="flex justify-center">
          <Oval
            height={50}
            width={50}
            color="#290349"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#fff"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        handleTopSaver()
      )}
    </div>
  );
}
