import axios from "axios";

// http://203.161.56.110:8000/v1/doc/#/api   //dev server
// https://api.onecap.africa  //main server
// http://203.161.56.110:8000/api

const axiosInst = axios.create({
  baseURL: "https://api.onecap.africa/api",
});

export const axiosNoApi = axios.create({
  baseURL: "https://api.onecap.africa",
});

export const axiosNoBase = axios.create({
  baseURL: "",
});

export default axiosInst;
