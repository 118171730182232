import { useContext, useState } from "react";
import { Context } from "../../../helpers/Context";
import Timer from "../../Timer";
import HCard from "../../../helpers/HCard";
import { Modal } from "@mui/material";
import {
  useUserInfo,
  useCycleData,
  useJackpotPoolInfo,
  useJackpotTicketData,
  useJackpotPoints,
  useJackpotWinningInfo,
  useUserProfile,
} from "../../../helpers/Hooks/Api";
import {
  getCorrectYear,
  getCurrentYear,
  useWeek,
} from "../../../helpers/Hooks/TimeFormat";
import { Oval } from "react-loader-spinner";

const CountDown = () => {
  useJackpotTicketData();
  useJackpotPoolInfo();
  useJackpotPoints();
  useJackpotWinningInfo();
  useUserInfo();
  useUserProfile();
  useCycleData();
  const [openEntries, setOpenEntries] = useState(false);
  const { state } = useContext(Context);
  const { prizeWeek } = useWeek();
  const {
    jackpotPoolInfo,
    jackpotWinningPrize,
    jackpotPoints,
    jackpotTickets,
    cycle,
    jackpotCurrentPrize,
    userInfo,
  } = state;

  const { isLoading } = useJackpotTicketData();

  const onCloseEntries = () => {
    setOpenEntries(false);
  };

  return (
    <div className="w-full flex flex-col items-center gap-y-[10px]">
      <div className="flex items-baseline">
        <span className=" text-[0.875rem] text-[#290349] font-AC font-[700]">
          Your Savings <span className=" ml-[5px]">=</span>{" "}
        </span>
        <span className="ml-[22px] text-[1rem] font-[700] text-[#290349]">
          N
        </span>
        {isLoading ? (
          <Oval
            height={30}
            width={30}
            color="#290349"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#592e6d"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        ) : (
          <span className="text-[1.75rem] font-[700] text-[#290349] font-SH">
            {(jackpotTickets?.length * 1000)
              ?.toString()
              ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </span>
        )}

        <span className="text-[2.25rem] font-[700] text-[#290349]">.</span>
        <span className="text-[1rem] font-[700] text-[#290349] font-SH">
          00
        </span>
      </div>

      <div className="flex items-baseline">
        <span className=" text-[0.875rem] text-[#290349] font-AC font-[700]">
          Your Reward <span className=" ml-[5px]">=</span>
        </span>
        <span className="ml-[22px] text-[1.75rem] font-[700] text-[#290349] font-SH">
          {userInfo?.jackpot_point}
        </span>
        <span className=" text-[0.875rem] text-[#290349] font-AC font-[700] ml-[2px]">
          OC Tokens
        </span>
      </div>

      <div className="flex gap-x-[12px] items-center">
        {isLoading ? (
          <Oval
            height={30}
            width={30}
            color="#290349"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#592e6d"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        ) : (
          <span className=" text-[2.25rem] text-[#290349] font-AC font-[700]">
            {jackpotTickets?.length}
          </span>
        )}

        <button
          className="ml-[12px] text-[0.875rem] text-[#290349] font-SF font-[700] underline cursor-pointer"
          onClick={() => setOpenEntries(true)}
        >
          Show entries
        </button>
      </div>

      <div className=" bg-[rgba(227,196,253,0.50)] w-full h-[240px] shadow-[0px_8px_24px_0px_rgba(0,0,0,0.15)] rounded-[10px] p-[18px] ">
        <div className=" text-[rgba(0,0,0,0.30)] text-[0.875rem] font-SF font-[800] space-[-0.078px]">
          PRIZE
        </div>

        <div className="mt-[18px] w-full h-[72px] flex items-center gap-x-[9px]">
          <div className="h-full w-[20.22%] flex items-center justify-center rounded-tl-[10px] rounded-bl-[10px] bg-[#702F90] shadow-[0px_4px_4px_#E3C4FD]">
            <img src="/assets/images/jar.png" alt="" />
          </div>

          <div className="h-full w-[77.23%] shadow-[0px_8px_24px_0px_rgba(0,0,0,0.15)] rounded-tr-[10px] rounded-br-[10px]">
            <div className="flex items-center justify-between py-[9px] px-[18px]">
              <span className=" text-[0.75rem] text-[rgba(0,0,0,0.80)] font-SF font-[500] ">
                Prize Amount
              </span>
              <span className=" text-[0.75rem] text-[rgba(0,0,0)] font-ENL">
                WEEK {prizeWeek}, {getCurrentYear()}
              </span>
            </div>

            <div className=" flex w-full items-center justify-between px-[18px]">
              <div className=" text-[1.5rem] font-[500]  bg-gradient-to-b  from-[#702F90] from-[0%] to-[#290349] to-[100%] bg-clip-text text-[transparent] ">
                ₦
                {parseFloat(jackpotWinningPrize)
                  .toFixed(0)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ".00"}
              </div>{" "}
              <span className=" flex text-[0.75rem] text-black font-ENL items-center gap-x-[2px]">
                {" "}
                {jackpotCurrentPrize?.percentage_increase}%{" "}
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={`${
                    !jackpotCurrentPrize?.is_bullish && "rotate-180"
                  }`}
                >
                  <path
                    d="M8 0L15.7942 13.5H0.205771L8 0Z"
                    fill={
                      jackpotCurrentPrize?.is_bullish ? "#4CAF50" : "#CD5C5C"
                    }
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
        <div className="mt-[13px] text-[0.75rem] text-[rgba(0,0,0,0.80)] text-center font-SF font-[500]">
          Countdown to prize draw
        </div>

        <div className="flex justify-center w-full ">
          <Timer time={jackpotPoolInfo?.raffle_draw_date} location="gamfi" />
        </div>
      </div>

      <Modal open={openEntries} onClose={onCloseEntries}>
        <div className="w-[22rem] md:w-[24.5rem] h-[calc(100%-4px)] md:h-[569px] rounded-[10px] bg-[#fff] p-[18px] m-[0_auto] mt-[4px] overflow-auto scrollbar-none md:scrollbar scrollbar-w-[8px] scrollbar-track-[rgba(227,196,253,0.50)] hover:scrollbar-thumb-[#484747] scrollbar-thumb-[transparent] scrollbar-track-rounded-[10px] scrollbar-thumb-rounded-[10px] relative">
          <div className="flex items-center justify-between fixed w-[20.5rem] md:w-[23rem] bg-white h-[4rem] top-[4px] rounded-tr-[10px] rounded-tl-[10px]">
            <span className=" text-[0.875rem] text-[#592E6D] font-SF font-[700]">
              Pool {cycle} Entries
            </span>{" "}
            <img
              src="/assets/images/closeBold.png"
              alt=""
              className="cursor-pointer "
              onClick={() => setOpenEntries(false)}
            />
          </div>

          <HCard
            child1="S/N"
            child2="Ticket ID"
            child3="Status"
            child4="Expiry Date"
            className=" flex items-center justify-between pb-[22px] border-b-[0.2px] border-b-[#FF1CF7] text-[0.75rem] text-[#592E6D] font-SF font-[700] mt-[4rem]"
          />

          {jackpotTickets?.length === 0 ? (
            <div className="text-[0.75rem] text-[#592E6D] font-SF font-[700] mt-[15px] text-center">
              No entry found!
            </div>
          ) : (
            jackpotTickets?.map((ticket, index) => {
              return (
                <HCard
                  key={index}
                  child1={index + 1}
                  child2={ticket?.id}
                  child3="Valid"
                  child4={ticket?.expiring_date}
                  ticket="true"
                />
              );
            })
          )}
        </div>
      </Modal>
    </div>
  );
};

export default CountDown;
