import { Box, Typography, Avatar } from "@mui/material";
import { Link } from "react-router-dom";
import NavLayout from "../NavLayout";
import { useContext } from "react";
import { ChevronRight } from "@mui/icons-material";
import { useUserWalletData } from "../../helpers/Hooks/Api";
import { Context } from "../../helpers/Context";
import { useLogout } from "../../helpers/Hooks/logout";

const Settings = () => {
  useUserWalletData();
  const { state } = useContext(Context);
  const { userWalletData } = state;
  const { logout } = useLogout();
  return (
    <NavLayout
      title="Settings"
      content={
        <Box maxWidth={"500px"} sx={{ margin: "0 auto" }}>
          <Box
            sx={{
              paddingTop: "20px",
              paddingLeft: "20px",
            }}
          >
            <Link to={"/settings/avatar"}>
              <Avatar src={userWalletData?.user_image} />
            </Link>

            <br />
            <Typography
              variant="p"
              sx={{
                color: "white",
                fontWeight: "400",
                fontSize: "28px",
                fontFamily: "Asap Condensed",
              }}
            >
              {userWalletData?.user?.split(" ")[0]}
            </Typography>
            <br />
            <Box sx={{ marginTop: "30px" }}>
              <Typography
                variant="p"
                sx={{
                  color: "white",
                  marginTop: "100px",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Earn points from referring friends to Onecap
              </Typography>
              <br />
              <Link
                to="/referrals"
                style={{
                  color: "white",
                  textDecoration: "underline",
                  fontSize: "14px",
                  fontWeight: "700",
                  paddingBottom: "30px",
                }}
              >
                Share with friends
              </Link>
            </Box>
          </Box>

          <Box sx={{ background: "#FFFFFF" }}>
            <Box
              sx={{
                paddingLeft: "20px",
                paddingTop: "20px",
              }}
            >
              <Typography
                sx={{
                  color: "#592E6D",
                  fontSize: "24px",
                  fontWeight: "400",
                }}
              >
                Account settings
              </Typography>
            </Box>
            <Box padding={"0 20px"}>
              <ContactRow
                icon={"/assets/images/info.png"}
                text="Personal Information"
                link="/settings/personalinfo"
              />
              <ContactRow
                icon={"/assets/images/helpDeep.png"}
                text="Learn-2-Earn"
                link="/quiz"
              />
              <ContactRow
                icon={"/assets/images/transPin.png"}
                text="Transaction Pin"
                link="/settings/transaction-pin"
              />
              <ContactRow
                icon={"/assets/images/rewards.png"}
                text="Rewards"
                link="/rewards-menu"
              />
              <ContactRow
                icon={"/assets/images/earnings.png"}
                text="Referrals"
                link="/referrals"
              />
              <ContactRow
                icon={"/assets/images/wallet.png"}
                text="Wallet Payout"
                link="/settings/walletpayout"
              />
              <ContactRow
                text="Notifications"
                link="/settings/notifications"
                icon={"/assets/images/notification.png"}
              />
              <ContactRow
                icon={"/assets/images/transHistory.png"}
                text="Transaction History"
                link="/transaction-history"
              />

              <ContactRow
                text="Terms of Service and Privacy Policy"
                link="/terms-and-conditions"
                icon={"/assets/images/tos.png"}
              />
              <ContactRow
                text="FAQ"
                link="/faq"
                icon={"/assets/images/help.png"}
              />

              <Box
                sx={{
                  marginLeft: "15px",
                  borderBottom: "0.1px solid #FF1CF7",
                  paddingBottom: "50px",
                  marginRight: "10px",
                }}
              >
                <button
                  className=" w-full h-[56px] rounded-[5px] bg-brandPurp text-white text-[0.75rem] font-AR mt-[75px]"
                  onClick={logout}
                >
                  Log out
                </button>
              </Box>
            </Box>
          </Box>
        </Box>
      }
    />
  );
};

const ContactRow = (props) => {
  return (
    <Link to={`${props.link}`}>
      <Box
        sx={{
          display: "flex",
          marginTop: "15px",
          borderBottom: "0.2px solid #FF1CF7",
          marginLeft: "10px",
          marginRight: "10px",
          paddingBottom: "10px",
          cursor: "pointer",
          justifyContent: "space-between",
        }}
      >
        <Box display={"flex"} alignItems="center" gap={"10px"}>
          <img src={props.icon} width="20px" height="20px" alt="" />
          <Typography
            sx={{
              paddingLeft: "0px",
              color: "#592E6D",
              fontSize: "16px",
              fontWeight: "400",
            }}
          >
            {props.text}
          </Typography>
        </Box>

        <Box>
          <ChevronRight />
        </Box>
      </Box>
    </Link>
  );
};

export default Settings;
