import { useContext, useState, useCallback, useEffect, useMemo } from "react";
import { ArrowBackIos } from "@mui/icons-material";
import { Modal, ThemeProvider, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Context } from "../../helpers/Context";
import { theme } from "../../helpers/MuiThemes";
import NavLayout from "../NavLayout";
import { useUserInfo } from "../../helpers/Hooks/Api";
import axios from "../../helpers/axios";
import { Oval } from "react-loader-spinner";
import Moment from "react-moment";
import Countdown from "react-countdown";
import moment from "moment";

const Quiz = () => {
  useUserInfo();
  const navigate = useNavigate();
  const { state } = useContext(Context);
  const { userInfo } = state;

  const [quiz, setQuiz] = useState({});
  const [noQuiz, setNoQuiz] = useState(false);
  const [allQuiz, setAllQuiz] = useState([]);
  const [topics, setTopics] = useState([]);
  const [quizPage, setQuizPage] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");
  const [showErrMsg, setShowErrMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [optionSelect, setOptionSelect] = useState(null);
  const [index, setIndex] = useState(0);
  const [finalAnswers, setFinalAnswers] = useState([]);
  const [fetchingQuiz, setFetchingQuiz] = useState(false);
  const [quizId, setQuizId] = useState(1);
  const [timeEnded, setTimeEnded] = useState("");
  const [currentQuestion, setCurrentQuestion] = useState({});

  const token = localStorage.getItem("token");
  const getNumOfAns = () => {
    quiz?.questions?.findIndex((que, indx) => {
      if (indx === index) {
        setCurrentQuestion(que);
      }
    });
  };

  const config = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }, [token]);

  useEffect(() => {
    getNumOfAns();
  }, [index]);

  const fetchAllQuiz = useCallback(async () => {
    setLoading(true);
    setNoQuiz(false);
    await axios
      .get("/quiz", config)
      .then(function (response) {
        if (response?.status === 204) {
          setNoQuiz(true);
          setLoading(false);
        } else {
          setAllQuiz(response?.data);
          setQuizPage(0);
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
        if (error?.response?.status === 401) {
          navigate("/signin");
        }
        if (error?.response?.status === 204) {
          setNoQuiz(true);
        }
      });
  }, []);

  useEffect(() => {
    fetchAllQuiz();
  }, [fetchAllQuiz]);
  const fetchQuiz = async () => {
    setIndex(0);
    setFetchingQuiz(true);
    await axios
      .get(`/quiz/${quizId}`, config)
      .then(function (response) {
        if (response.data !== undefined) {
          setTimeEnded(
            moment(response.headers.Http_x_quiz_start_time)
              .add(parseInt(response?.data?.time_allowed?.split(":")[1]), "m")
              .toDate()
          );

          setQuiz(response?.data);
          setQuizPage(2);
          setFetchingQuiz(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setFetchingQuiz(false);
        if (error?.response?.status === 401) {
          navigate("/signin");
        }

        if (error?.response?.status === 403) {
          setShowErrMsg(true);
          setErrorMsg(error?.response?.data?.message || "Quiz has ended.");
          setTimeout(() => {
            setShowErrMsg(false);
          }, 5000);
        }
        if (error?.response?.status === 400) {
          setShowErrMsg(true);
          setErrorMsg(
            error?.response?.data?.message ||
              "You cannot participate more than once."
          );
          setTimeout(() => {
            setShowErrMsg(false);
          }, 5000);
        }
      });
  };

  const fetchAllTopics = useCallback(async () => {
    await axios
      .get("/quiz-topic", config)
      .then(function (response) {
        if (response?.data?.success === true) {
          setTopics(response?.data?.data);
          setLoading(false);
        } else {
          setNoQuiz(true);
          setLoading(false);
        }
      })
      .catch(function (error) {
        setFetchingQuiz(false);
        if (error?.response?.status === 400) {
          setShowErrMsg(true);
          setErrorMsg(
            "There's a problem with getting Learn-2-Earn topics. Try again later."
          );
          setTimeout(() => {
            setShowErrMsg(false);
          }, 5000);
        }
      });
  }, []);
  useEffect(() => {
    fetchAllTopics();
  }, [fetchAllTopics]);

  const handleDisplayTopic = (topicId) => {
    const selectedTopic = topics.find((topic) => topic.id === topicId);
    if (selectedTopic) {
      return (
        <div className=" rounded-[10px] p-[21px_19px] w-full bg-white border-[0.5px] border-[#ff1cf7] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] flex flex-col gap-y-[28px] mt-[26.39px] h-[60vh]">
          <h2 className="w-full text-center text-[#592E6D] text-[0.875rem] font-[700]">
            {selectedTopic.topic_name.charAt(0).toUpperCase() +
              selectedTopic.topic_name.slice(1)}
          </h2>
          <p className="text-[#592E6D] w-full text-[0.75rem]">
            {selectedTopic.content.charAt(0).toUpperCase() +
              selectedTopic.content.slice(1)}
          </p>
          <div className="flex flex-col gap-[5px] ">
            <p className="font-[700] text-[#592E6D] text-[0.875rem]">
              Attempt Quiz
            </p>
            <p className="text-[#592E6D] text-[0.75rem]">
              Earn 10 OC Tokens for passing the quiz.
            </p>
            <p className="text-[#592E6D] text-[0.75rem]">Time: 4 minutes.</p>
          </div>
          <button
            className="bg-[#592E6D] text-[0.875rem] py-[10px]"
            onClick={() => {
              setQuizId(selectedTopic?.id);
              localStorage.setItem("currentQuizId", selectedTopic?.id);
              const selectedQuiz = allQuiz.find(
                (quiz) => quiz?.id === selectedTopic?.id
              );
              localStorage.setItem("currentQuizName", selectedQuiz?.quiz_name);
              setQuizPage(1);
            }}
          >
            Start Quiz
          </button>
          <p
            className="underline text-[#592E6D] text-[0.875rem] text-center"
            onClick={() => {
              setQuizPage(0);
            }}
          >
            Close
          </p>
        </div>
      );
    }
  };

  const handleSubmitAnswers = useCallback(async () => {
    const data = {
      quiz_id: quiz?.id,
      answers: finalAnswers,
    };
    setLoading(true);
    await axios
      .post("/quiz/submit", data, config)
      .then(function (response) {
        localStorage.setItem("quizResult", JSON.stringify(response?.data));
        setLoading(false);
        navigate("/quiz-result");
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
        if (error?.response?.status === 401) {
          navigate("/signin");
        }
        setQuizPage(0);
      });
  }, [finalAnswers, quiz?.id]);

  const handleSelectAnswers = (questionId, answerId) => {
    setOptionSelect(answerId);
    setFinalAnswers((answers) => [
      ...answers?.filter((ans) => ans?.question_id !== questionId),
      { question_id: questionId, answer_id: answerId },
    ]);
  };

  const Previous = () => {
    index >= 1 && setIndex((prev) => prev - 1);
    setOptionSelect(null);
  };

  const Next = () => {
    index < quiz?.questions?.length - 1 && setIndex((prev) => prev + 1);
    setOptionSelect(null);
  };

  return (
    <NavLayout
      title="Quiz"
      content={
        <section className=" max-w-[500px] sm:m-[0_auto] pb-[5rem]">
          <div>
            <div className="p-[24px_20px] border-b-[0.1px_solid_#ffffff80]">
              <div className=" w-[100%] flex items-center gap-[35%] text-[rgba(255,255,255,0.87)] text-[1rem] font-AR mb-[10px]">
                <ArrowBackIos
                  sx={{ fontSize: "16px", cursor: "pointer" }}
                  onClick={() => {
                    navigate(-1);
                  }}
                />
                Learn-2-Earn
              </div>
            </div>
          </div>
          <div className=" p-[0_20px] text-[#fff] relative">
            <div
              sx={{ margin: "10px 0" }}
              className=" m-[10px_0] text-[0.875rem] font-SF"
            >
              Summary
            </div>
            <div className=" rounded-[10px] border-[0.2px] border-[#ff1cf7] bg-[rgba(255,255,255,0.20)] w-full h-[120px] p-[0_18px] backdrop-blur-[20px] bg-blend-overlay flex items-center justify-between">
              <div className=" pr-[18px] border-r-[0.2px] border-r-[#ff1cf7]">
                <img src="/assets/images/cash.png" alt="" />
              </div>
              <div>
                <p className=" text-[0.75rem] text-[#fff] font-SF font-[200] text-center mb-[16px]">
                  Token Balance
                </p>
                <div className=" flex gap-x-[6px] items-center">
                  <p className=" text-[1.75rem] text-[#fff] font-SH leading-[20px] tracking-[-0.24px] text-center">
                    {userInfo?.available_token_point}
                  </p>
                  <p className=" text-[0.875rem] text-[#fff] font-SF font-[200] leading-[18px] tracking-[-0.078px]">
                    OC Tokens
                  </p>
                </div>
              </div>

              <div className=" flex flex-col items-center gap-y-[9px]">
                <p className=" text-[0.875rem] text-[#fff] font-SF font-[200] leading-[18px] tracking-[-0.078px]">
                  Rank
                </p>

                <ThemeProvider theme={theme}>
                  <Tooltip title={userInfo?.name_rank}>
                    <img
                      src={`/assets/images/avatars/${
                        userInfo?.name_rank
                          ? userInfo?.name_rank
                          : "Rookie Capstar I (RC 1)"
                      }.png`}
                      alt="rank"
                      className=" w-[24px] h-[24px] rounded-[50%] border-[2px] border-[#fff]"
                    />
                  </Tooltip>
                </ThemeProvider>
              </div>
            </div>

            {(quizPage === 0 || quizPage === 1) && (
              <div>
                <div className=" rounded-[10px] p-[21px_19px] w-full bg-white border-[0.5px] border-[#ff1cf7] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] flex flex-col gap-y-[28px] mt-[26.39px] h-[60vh]">
                  <div>
                    <p className=" underline text-[#290349] text-[0.875rem] font-AR leading-[18px] tracking-[-0.08px] mb-[10px]">
                      Learn-2-Earn
                    </p>
                    <p className=" text-[#702f90] text-[0.75rem] font-AR leading-[30px] tracking-[-0.078px] mb-[10px]">
                      Test your financial literacy by taking any of our
                      Learn-2-Earn.
                      <br />
                      You earn 10 OC Tokens on successful completion of a
                      Learn-2-Earn!
                    </p>
                  </div>
                  {loading && (
                    <div className=" h-full flex flex-col items-center m-[15px_0]">
                      <Oval
                        height={80}
                        width={80}
                        color="#290349"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#592e6d"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                      />
                    </div>
                  )}

                  {noQuiz ? (
                    <div className=" text-[#702F90] text-[0.75rem] font-MV font-[700]">
                      <p className="flex flex-row gap-[10px]">
                        Sorry, there are no available Learn-2-Earn this week!
                        <img
                          src="/assets/images/VectorNoQuiz.png"
                          alt=""
                          className="h-[15px] w-[15px]"
                        />
                      </p>
                      <p>Check Back Later!</p>
                    </div>
                  ) : (
                    topics.map((topic) => (
                      <div
                        key={topic?.id}
                        className="flex border-[1px] border-[#702f90] rounded-[10px] items-center p-[15px] shadow-shadow  gap-[10px]"
                        onClick={() => {
                          setQuizId(topic?.id);
                          localStorage.setItem("currentQuizId", topic?.id);
                          setQuizPage(-1);
                          handleDisplayTopic(topic?.id);
                        }}
                      >
                        <div className="flex flex-col gap-[5px]">
                          <p className="text-[#592E6D] text-[0.875rem] font-[700]">
                            {topic?.topic_name.toUpperCase()}
                          </p>
                          <p className="text-[#592E6D] text-[0.75rem]">
                            {topic?.content?.split(" ").slice(0, 10).join(" ") +
                              (topic?.content?.split(" ").length > 10
                                ? "..."
                                : "")}
                          </p>

                          <p className="text-[#702F90] text-[0.75rem] font-[700]">
                            Published:{" "}
                            <span>
                              <Moment format="DD/MM/YY">
                                {topic?.date_created}
                              </Moment>
                            </span>
                          </p>
                        </div>
                        <div className="w-[60px] h-[60px] overflow-hidden">
                          <img
                            src="/assets/images/quizCategory.png"
                            alt="article"
                            className="object-contain w-full h-full"
                          />
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            )}
            {quizPage === -1 && <div>{handleDisplayTopic(quizId)}</div>}

            <Modal
              open={quizPage === 1}
              onClose={() => setQuizPage(0)}
              className="flex items-center justify-center"
            >
              <div className=" w-[23.75rem] bg-[#fff] px-[12px] pb-[36px] pt-[12px] rounded-[10px]">
                <div className="float-right ">
                  <img
                    src="/assets/images/close.png"
                    alt=""
                    className=" cursor-pointer h-[16px] w-[16px]"
                    onClick={() => setQuizPage(0)}
                  />
                </div>

                <div className=" flex flex-col items-center gap-y-[47px] mt-[36px]  text-[#290349] font-SF font-[600]">
                  <p className=" text-[0.875rem]">Test your knowledge about</p>
                  <p className=" text-[1.125rem] text-center">
                    {localStorage.getItem("currentQuizName")?.toUpperCase()}
                  </p>

                  {fetchingQuiz && (
                    <div className="flex flex-col items-center">
                      <Oval
                        height={40}
                        width={40}
                        color="#290349"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#592e6d"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                      />
                    </div>
                  )}

                  <button
                    className=" bg-brandPurp text-white w-[20rem] h-[56px] text-[0.875rem]  rounded-[5px] font-SF font-[600]"
                    onClick={fetchQuiz}
                  >
                    START QUIZ
                  </button>
                </div>

                {errorMsg !== null && showErrMsg && (
                  <div className=" text-center mt-[10px]  text-[#702F90] text-[0.75rem] font-AR font-[700]]">
                    {errorMsg}
                  </div>
                )}
              </div>
            </Modal>

            {quizPage === 2 && (
              <>
                <div className=" m-[10px_0] text-white text-center w-full text-[1rem] font-AR font-[700]">
                  Total marks : {quiz?.questions?.length} | Time :{" "}
                  {quiz?.time_allowed?.split(":")[1]}
                  mins
                </div>

                <div className=" rounded-[10px] p-[21px_19px] w-full bg-white border-[0.5px] border-[#ff1cf7] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] flex flex-col gap-y-[28px]">
                  <div className=" w-full flex items-center justify-between text-black text-[0.625rem]">
                    <span>
                      Choose 1 out of {currentQuestion?.answers?.length} answers
                    </span>{" "}
                    <div className="flex items-center gap-x-[21px]">
                      <img
                        src="/assets/images/flag.png"
                        alt=""
                        className=" w-[10px] h-[9px]"
                      />{" "}
                      <span className="flex items-center gap-x-[4px]">
                        <img src="/assets/images/time.png" alt="" />
                        <span className="text-[0.6875rem]">
                          <Countdown
                            date={timeEnded}
                            renderer={({ minutes, seconds }) => (
                              <span className="text-black ">
                                {minutes}:{seconds}
                              </span>
                            )}
                            onComplete={() => handleSubmitAnswers()}
                          />
                        </span>
                      </span>{" "}
                    </div>
                  </div>
                  <div className="text-black font-AR">
                    <p className=" mt-[30px] mb-[16px] max-w-[305px] font-[700] text-[0.75rem] leading-[18px] tracking-[-0.078px]">
                      {quiz?.questions !== undefined &&
                        quiz?.questions[index]?.question_value}
                    </p>
                  </div>
                  <div className=" w-full flex flex-col gap-y-[28px]">
                    {quiz?.questions[index]?.answers?.map((answer) => (
                      <div
                        key={answer?.id}
                        className=" w-full p-[11px_15px] rounded-[10px] border-[1px] border-[#ff1cf7] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] flex items-center justify-between cursor-pointer"
                        onClick={() =>
                          handleSelectAnswers(
                            quiz?.questions[index]?.id,
                            answer?.id
                          )
                        }
                      >
                        <p className=" text-[0.75rem] text-black leading-[18px] tracking-[-0.078px] ">
                          {answer?.answer_value}
                        </p>
                        <img
                          src="/assets/images/select.png"
                          alt=""
                          className={` ${
                            answer?.id === optionSelect
                              ? "opacity-100 transition-opacity duration-500 ease-in-out"
                              : "opacity-0 transition-opacity duration-500 ease-in-out"
                          }`}
                        />
                      </div>
                    ))}
                  </div>

                  <div className=" mt-[15px] flex items-center justify-between">
                    {index > 0 && (
                      <button
                        className=" w-[6.875rem] bg-[transparent] p-[11px] rounded-[10px] flex gap-x-[6px] text-black text-[0.65rem] leading-[18px] items-center justify-center "
                        onClick={Previous}
                      >
                        <img src="/assets/images/back.png" alt="" /> Back
                      </button>
                    )}
                    <button
                      className=" w-[6.875rem] bg-brandPurp border-[1px] border-brandPurp p-[11px] rounded-[10px] flex gap-x-[6px] text-white text-[0.65rem] leading-[18px] items-center justify-center"
                      onClick={
                        index >= quiz?.questions?.length - 1
                          ? handleSubmitAnswers
                          : Next
                      }
                    >
                      {index >= quiz?.questions?.length - 1 ? "Submit" : "Next"}{" "}
                      <img src="/assets/images/next.png" alt="" />
                    </button>
                  </div>
                </div>
              </>
            )}

            {quizPage === 4 && !loading && (
              <div className=" rounded-[10px] p-[21px_19px] w-full bg-white border-[0.5px] border-[#ff1cf7] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] flex flex-col gap-y-[28px]">
                <p className=" text-center mt-[10px] text-brandPurp text-[0.75rem]">
                  You have exhusted your time for this quiz! Thank you for
                  participating.
                </p>
              </div>
            )}
          </div>
        </section>
      }
    ></NavLayout>
  );
};

export default Quiz;
