import React from "react";
import Divider from "../../../helpers/Divider";
import NavLayout from "../../NavLayout";
import { useNavigate } from "react-router-dom";

export default function Intro() {
  const navigate = useNavigate();
  return (
    <NavLayout
      title="Gamfi Pool"
      content={
        <div className="max-w-[500px] m-[0_auto]">
          <section className="flex items-center pt-[35px] px-[18px]">
            <img
              src="/assets/images/slideBack.png"
              alt=""
              className="cursor-pointer "
              onClick={() => navigate(-1)}
            />
            <span className="w-full text-center text-[1rem] text-[rgba(255,255,255,0.87)] font-SF font-[400] space-x-[0.15px]">
              Community Jackpot Pool
            </span>
          </section>
          <Divider />

          <div className="bg-white flex flex-col items-center p-[20px] gap-[20px] rounded-[10px] mx-[10px] text-[#592E6D]">
            <img src="/assets/images/jackpot.png" alt="" className="" />
            <p>Introducing!</p>
            <p className="font-[700]">Community Jackpot Pool</p>
            <div className="flex flex-col gap-[10px]">
              <div className="flex bg-[rgb(255,28,247,10%)] items-center gap-[10px] p-[10px] rounded-[5px]">
                <img
                  src="/assets/images/bullet.png"
                  alt=""
                  className="w-[24px] h-[24px]"
                />
                <p className="leading-3 text-[0.75rem]">
                  OneCap partners with influential university ambassador to
                  create customized pools.
                </p>
              </div>
              <div className="flex bg-[rgb(255,28,247,10%)] items-center gap-[10px] p-[10px] rounded-[5px]">
                <img
                  src="/assets/images/bullet.png"
                  alt=""
                  className="w-[24px] h-[24px]"
                />
                <p className="leading-3 text-[0.75rem]">
                  Participants join the pool with a minimum of N1,000 (min
                  amount varies based on the community preference), earning
                  tickets for every amount stacked.
                </p>
              </div>
              <div className="flex bg-[rgb(255,28,247,10%)] items-center gap-[10px] p-[10px] rounded-[5px]">
                <img
                  src="/assets/images/bullet.png"
                  alt=""
                  className="w-[24px] h-[24px]"
                />
                <p className="leading-3 text-[0.75rem]">
                  The more participants stake, the more tickets they earn
                  increasing their chances of winning.
                </p>
              </div>
              <div className="flex bg-[rgb(255,28,247,10%)] items-center gap-[10px] p-[10px] rounded-[5px]">
                <img
                  src="/assets/images/bullet.png"
                  alt=""
                  className="w-[24px] h-[24px]"
                />
                <p className="leading-3 text-[0.75rem]">
                  Every weekday, an automated raffled draw selects one winner to
                  claim the jackpot price.
                </p>
              </div>
              <div className="flex bg-[rgb(255,28,247,10%)] items-center gap-[10px] p-[10px] rounded-[5px]">
                <img
                  src="/assets/images/bullet.png"
                  alt=""
                  className="w-[24px] h-[24px]"
                />
                <p className="leading-3 text-[0.75rem]">
                  Everyone receives their capital back at the end of the cycle,
                  along with OneCap tokens.
                </p>
              </div>
            </div>
            {/* <button
              className="bg-[#592E6D] text-white text-[0.75rem] rounded-[5px] py-[10px] px-[100px] cursor-pointer"
              onClick={() => navigate("/communities")}
            >
              Join a Community
            </button> */}
            <p>Coming Soon</p>
          </div>
        </div>
      }
    />
  );
}
