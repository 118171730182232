import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import NavLayout from "../NavLayout";
import { useManageOtp, useUpdateDetails } from "../../helpers/Hooks/Api";
import "react-phone-number-input/style.css";
import "./styles.css";
import PhoneInput from "react-phone-number-input";
import { useState } from "react";
import PinOtpPopUp from "./PinOtpPopUp";
import PinOtpError from "./PinOtpError";
import { Oval } from "react-loader-spinner";

const Changephone = () => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState();
  const [openOtp, setOpenOtp] = useState(false);
  const { update, serverError } = useUpdateDetails();
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [timeLeft, setTimeLeft] = useState(60);
  const {
    initiateOtp,
    sendingOtp,
    otpError,
    isOtpError,
    setIsOtpError,
    verifyOtp,
    serverError: verifyPhoneError,
  } = useManageOtp({ type: "phone", phone });

  const updatePhone = () => {
    setOpenOtp(false);
    update(
      {
        phone,
        address: null,
        user_image: "",
        land_mark_area: null,
        state: null,
        local_government_area: null,
        bvn: "",
      },
      "Phone number updated successfully"
    );
  };

  return (
    <NavLayout
      title="Update Full Name"
      content={
        <Box
          maxWidth={"500px"}
          sx={{
            backgroundColor: "#fff",
            minHeight: "100vh",
            margin: "0 auto",
            paddingTop: "1px",
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: "flex",
              marginTop: "20px",
              justifyContent: "center",
              alignItems: "center",
              
            }}
          >
            <img
              src={"/assets/images/close.png"}
              alt=""
              onClick={() => navigate(-1)}
              className=" absolute left-[14px] cursor-pointer"
            />

            <Typography className=" text-brandPurp text-[1.125rem] font-AR">
              Update Phone Number
            </Typography>
          </Box>

          <Box width={"100%"} px={"20px"} margin={"0px auto"}>
            <Box
              sx={{
                marginTop: "40px",
              }}
            ></Box>

            <div className=" text-center text-brandPurp text-[0.875rem] max-w-[338px] mt-[58px] mb-[65px] m-[0_auto]">
              Update your contact information
            </div>
            <div>
              <PhoneInput
                placeholder="PHONE NUMBER"
                defaultCountry="NG"
                value={phone}
                onChange={setPhone}
              />

              <Typography variant="caption" className="mt-[20px]">
                {serverError}
              </Typography>

              <Box margin="100px auto" width="100%">
                <button
                  onClick={() => initiateOtp(setOpenOtp)}
                  className=" rounded-[5px] bg-brandPurp w-full h-[56px] flex items-center justify-center text-white text-[0.75rem] font-SF font-[600] disabled:opacity-50"
                  disabled={!phone}
                >
                  {sendingOtp ? (
                    <Oval
                      height={15}
                      width={15}
                      color="#FFFFFF"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#592e6d"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  ) : (
                    "Save"
                  )}
                </button>
              </Box>
            </div>
          </Box>
          <PinOtpPopUp
            open={openOtp}
            setOpen={setOpenOtp}
            otp={otp}
            setOtp={setOtp}
            onSubmit={() => verifyOtp(otp, () => updatePhone())}
            onResend={() => initiateOtp(setOpenOtp)}
            error={verifyPhoneError}
            timeLeft={timeLeft}
            setTimeLeft={setTimeLeft}
            type="phone"
          />
          <PinOtpError
            open={isOtpError}
            onClose={() => setIsOtpError(false)}
            otpError={otpError}
          />
        </Box>
      }
    />
  );
};

export default Changephone;
