import { Modal } from "@mui/material";

const PinOtpError = ({ open, onClose, otpError }) => {
  return (
    <Modal open={open} onClose={onClose} className={`flex items-center`}>
      <section
        className={`w-[24.2574rem] h-[180px]  m-[0_auto] p-[10px] mt-[2rem] transition-transform duration-700 ease-in-out bg-[#fff] shadow-[0px_11px_15px_0px_rgba(0,0,0,0.20),0px_9px_46px_0px_rgba(0,0,0,0.12),0px_24px_38px_0px_rgba(0, 0, 0, 0.14)] rounded-[4px] flex flex-col items-center`}
      >
        <div
          className="flex self-start justify-end w-full cursor-pointer "
          onClick={() => onClose()}
        >
          <img
            src="/assets/images/closeBold.png"
            alt=""
            className=" h-[24px] w-[24px]"
          />
        </div>
        <div className="text-[1rem] text-[#592E6D] font-SF font-[600] flex items-center justify-center mt-[37px]">
          Oops! That didn’t work
        </div>
        <div className=" max-w-[20.375rem] text-center m-[0_auto] text-[0.75rem] text-[#592E6D] font-SF font-[400]">
          {otpError ? otpError : "Something went wrong! Please try again."}
        </div>
      </section>
    </Modal>
  );
};

export default PinOtpError;
