export const SuccessPopUp = ({ open, onClose, message }) => {
  return (
    <div
      className={`w-full h-full fixed inset-0 bg-[rgba(0,0,0,0.5)] flex items-center justify-center z-50 text-[#592E6D] ${
        open ? "block" : "hidden"
      }`}
    >
      <div className="relative flex flex-col items-center justify-center bg-white p-[30px] rounded-md shadow-lg">
        <div className="flex justify-end w-full">
          <img src="/assets/images/close.png" alt="close" onClick={onClose} />
        </div>
        <p className="text-center ">Success </p>
        <p className="text-center text-[0.9rem] py-[15px]"> {message}</p>
      </div>
    </div>
  );
};
