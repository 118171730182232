import React, { useState } from "react";
import { Box, Typography, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import NavLayout from "../NavLayout";
import { formstyles } from "../../helpers/Styles";
import { useFeedBack } from "../../helpers/Hooks/Api";
import { SuccessPopUp } from "./SuccessPopUp";

const feelings = ["Happy", "Good", "Neutral", "Bad"];

const FeedBack = () => {
  const { createFeedBack, serverError, showSuccessPopUp } = useFeedBack();
  const navigate = useNavigate();
  const [selectedFeeling, setSelectedFeeling] = useState(null);

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm();

  const onSubmit = async (formData) => {
    await createFeedBack(formData);
  };

  const handleFeelingSelect = (feeling) => {
    setSelectedFeeling(feeling);
    setValue("rating_feeling", feeling);
  };

  return (
    <NavLayout
      title="FeedBack"
      content={
        <Box
          maxWidth={"500px"}
          sx={{
            backgroundColor: "#fff",
            minHeight: "100vh",
            margin: "0 auto",
            padding: "20px",
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: "flex",
              marginTop: "20px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/assets/images/close.png"
              alt=""
              onClick={() => navigate(-1)}
              className="absolute left-[14px] cursor-pointer"
            />
            <Typography className="text-brandPurp text-[1.125rem] font-AR">
              Submit Feedback
            </Typography>
          </Box>

          <p className="mt-[80px] text-center text-[#592E6D]">
            We would like to hear from you. Please share your experience using
            OneCap, and tell us how we can improve.
          </p>

          <form onSubmit={handleSubmit(onSubmit)} className="mt-[50px]">
            <input
              type="hidden"
              {...register("rating_feeling", {
                required: "Please select a feeling.",
              })}
            />
            <div className="grid grid-cols-2 gap-[30px]">
              {feelings.map((feeling) => (
                <div
                  key={feeling}
                  onClick={() => handleFeelingSelect(feeling)}
                  className={`h-[45px] rounded-[10px] flex px-[15px] py-[10px] gap-[10px] cursor-pointer transition-all duration-200 ${
                    selectedFeeling === feeling
                      ? "bg-[#9563AF] text-white"
                      : "bg-[#EBEBEB] text-[#592E6D]"
                  }`}
                >
                  <img
                    src="/assets/images/feedbackCheck.png"
                    alt="check mark"
                    className="h-[24px] w-[24px]"
                  />
                  <span>{feeling}</span>
                </div>
              ))}
            </div>
            {errors.rating_feeling && (
              <p className="mt-2 text-sm text-red-500">
                {errors.rating_feeling.message}
              </p>
            )}

            <p className="text-[#592E6D] mt-[40px] mb-[10px]">
              Share your thoughts
            </p>
            <Box
              sx={{
                borderRadius: "5px",
                marginBottom: "20px",
                backgroundColor: "#EBEBEB",
              }}
            >
              <TextField
                required
                fullWidth
                multiline
                rows={8}
                placeholder="Type here..."
                type="text"
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  style: { color: "#B5B5B5", padding: "10px" },
                }}
                sx={formstyles}
                {...register("thoughts", {
                  required: "Please share your thoughts on how we can improve.",
                  minLength: {
                    value: 4,
                    message: "Message too short, enter at least 3 words.",
                  },
                })}
                error={!!errors.thoughts}
                helperText={errors.thoughts?.message}
              />
            </Box>

            {serverError && (
              <Typography variant="caption" color="error">
                {serverError}
              </Typography>
            )}

            <Box margin="40px auto" width="100%">
              <button
                type="submit"
                className="rounded-[5px] bg-[#592E6D] w-full h-[56px] text-white text-[0.75rem] font-SF font-[600]"
              >
                Submit Feedback
              </button>
            </Box>
          </form>
          <SuccessPopUp
            open={showSuccessPopUp}
            onClose={() => {
              navigate("/dashboard");
            }}
            message={"Thank you for leaving us a feedback!"}
          />
        </Box>
      }
    />
  );
};

export default FeedBack;
